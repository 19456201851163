import React from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { LazyMotion, m } from 'framer-motion';
import { Box } from 'grommet';

const MotionBox = m(Box);

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

export default function DynamicAnimator({ blok }) {
  const { items, loop, duration, type, ...rest } = blok;

  return (
    <LazyMotion strict features={loadFeatures}>
      <MotionBox
        animate={getAnimation(type)}
        transition={{
          duration: duration ? parseFloat(duration) : 2,
          repeat: !!loop ? Infinity : 0,
        }}
        {...storyblokEditable(blok)}
        {...rest}
      >
        {blok.items.map((blok) => (
          <StoryblokComponent key={blok.uuid} blok={blok} />
        ))}
      </MotionBox>
    </LazyMotion>
  );
}

function getAnimation(animation) {
  switch (animation) {
    case 'bounce':
      return { scale: [1, 1.5, 1.5, 1, 1], rotate: [0, 0, 270, 270, 0] };
    case 'spin':
      return { rotate: [0, 360] };
    case 'pulse':
      return { scale: [1, 1.5, 1.5, 1] };
    case 'shake':
      return { x: [0, 20, -20, 20, -20, 0] };
    case 'slide':
      return { x: [0, 100, -100, 100, -100, 0] };
    case 'fade':
      return { opacity: [1, 0.5, 1] };
    case 'flip':
      return { rotateY: [0, 180] };
    case 'twinkle':
      return { opacity: [1, 0, 1, 0, 1] };
    case 'sway':
      return {
        scale: [1, 1.05, 1],
        rotate: [0, 1, -1, 0],
      };
    case 'sway-twinkle':
      return {
        scale: [1, 1.1, 1],
        rotate: [0, 1, -1, 0],
        opacity: [1, 0.75, 1, 0.75, 1],
      };
    default:
      return {};
  }
}
